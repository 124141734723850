// Fonts
@import url('https://fonts.googleapis.com/css?family=Nunito');
@import 'button';
@import 'menu';
@import 'dropzone';

.active {
    color: #4b71fa !important;
}

strong,
b {
    font-weight: 700 !important;
}

.scroll-body {
    height: 495px;
    overflow-y: scroll;
}

.pac-container {
    z-index: 99999;
}

#cover-spin {
    background-color: rgba(255, 255, 255, 0.7);
    bottom: 0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    width: 100%;
    z-index: 9999;

    &:after {
        animation: spin .8s linear infinite;
        border-color: black;
        border-radius: 50%;
        border-style: solid;
        border-top-color: transparent;
        border-width: 4px;
        content: '';
        display: block;
        height: 40px;
        left: 48%;
        position: absolute;
        top: 40%;
        width: 40px;
    }
}

@keyframes spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

.anchor-div {
    display: inline-block;
    cursor: pointer;
    color: #80C1D6;
}

.error {
    color: #e3342f;
}

hr.solid {
    border-top: 3px solid #bbbb;
}

#container {
    float: right;
}

#submit_con {
    float: left;
}

#search_con {
    float: left;
}

.MuiButtonBase-root {
    display: flex !important;
}

.MuiFormHelperText-root {
    &.Mui-error {
        color: #e3342f !important;
        font-size: 14px;
        ;
        font-style: italic;
    }
}

.MuiTabs-scroller {
    button {
        padding: 0 10px;
    }
}

.li-order-listing__controls {
    align-items: center;
    display: flex;
    justify-content: center;

    a {
        margin: 0 5px;
    }
}

.li-order-listing__status-filter {
    align-items: center;
    display: flex;
    height: 50px;
    list-style: none;
    overflow-x: auto;

    li {
        flex-shrink: 0;
    }
}

.li-order-listing__status-filter__link {
    &.active {
        font-weight: 700;
    }
    &.no-sync {
        color: red !important;
    }
}

.form-group {
    .currency-prefix {
        color: $mischka;
        left: 20px;
        position: absolute;
        top: 36.5px;
    }
}

.form-control {
    &.currency-input {
        padding-left: 15px !important;
    }
}

.right-input {
    input {
        text-align: right;
        width: 80px;
    }
}

.modal-close {
    display: flex;
    position: relative;
    justify-content: flex-end;

    i {
        position: absolute;
        cursor: pointer;
    }
}

.modal-title {
    margin-bottom: 25px;
}

.modal-checkboxes {
    display: flex;
}

.modal-column {
    padding-right: 10px;
}

.modal-checkbox-label {
    display: flex;
}

.modal-submit {
    display: flex;
    justify-content: flex-end;
    margin-top: 25px;
}

.modal-button {
    outline: none;
    border: none;
    cursor: pointer;
    padding: 5px 44px;
}

.ReactModal__Overlay--after-open {
    z-index: 99999;
}


.spinner {
    width: 14px;
    height: 14px;
    border: 2px solid #808080;
    border-bottom-color: transparent;
    border-radius: 50%;
    display: inline-block;
    box-sizing: border-box;
    animation: rotation 1s linear infinite;
}

@keyframes rotation {
    0% {
        transform: rotate(0deg);
    }

    100% {
        transform: rotate(360deg);
    }
}

.jodit-react-container{

   .jodit-container
   {
      // text can start 10 px within the editor
      .jodit-wysiwyg {
         padding-left: 10px;
      }
      // allows default ul and dot to show up
      ul {
         list-style: inside;
      }
      // allows default ol to show up
      ol {
         list-style: inside;
         list-style-type: number;
      }
      // allows table borders to show up
      td {
         border: solid 1px;
      }
   }
}
